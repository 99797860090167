import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
  crossdomain: true,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Origin': '*',
  }
});

// const API = axios.create({ baseURL: process.env.REACT_APP_LOCAL_BACKEND_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`;
  }
  return req;
});

// eslint-disable-next-line import/prefer-default-export
export const fetchEmployee = () => API.get('/employee');
export const createEmployee = (newEmployee) => API.post('/employee', newEmployee)
  .catch((error) => console.log('api createEmployee error: ', error).send(error));

export const login = (formData) => API.post('/user/login', formData)
  .catch((err) => {
    return err?.response || err?.message;
  });

export const register = (formData) => API.post('/user/register', formData)
  .catch(({ response }) => {
    return response;
  });

export const activateAccount = (formData) => API.put('/user/activateAccount', formData)
  .catch(({ response }) => {
    return response;
  });

export const registerbysuperadmin = (formData) => API.post('/user/registerbysuperadmin', formData)
  .catch(({ response }) => {
    return response;
  });

export const fetchPersonalTicket = (employeeNumber) => API.get(`/service/personalTicket/${employeeNumber}`)
  .catch(({ response }) => {
    return response;
  });

export const fetchPeoples = (employeeNumber) => API.get(`/service/peoples/${employeeNumber}`)
  .catch(({ response }) => {
    return response;
  });

export const fetchManageTicket = (employeeNumber) => API.get(`/service/manageTicket/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const createTicket = (formData) => API.post('/service/createTicket', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createRequest = (formData) => API.post('/service/createRequest', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchTicketDetails = (serviceRefno) => API.get(`/service/ticketDetails/${serviceRefno}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchAccountType = (employeeNumber) => API.get(`/user/accountType/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const updateTicket = (formData) => API.post(`/service/updateTicket/${formData?.service_refno || null}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const uploadFile = (formData) => API.post(`/service/uploadFile/${formData?.service_refno || null}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const forwardTicket = (formData) => API.post(`/service/forwardTicket/${formData?.service_refno || null}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const forwardChangeOwnerTicket = (formData) => API.post(`/service/forwardChangeOwnerTicket/${formData?.service_refno || null}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchOtherDivisionUser = (employeeNumber, operation) => API.get(`/user/otherDivisionUser/${employeeNumber}-${operation || null}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchOtherDivisionUserVendor = (employeeNumber) => API.get(`/user/otherDivisionUserVendor/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchTicketSeverity = (employeeNumber) => API.get(`/service/ticketSeverity/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchTicketModule = (employeeNumber) => API.get(`/service/ticketModule/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchAllTicketModule = () => API.get('/service/allTicketModule')
  .catch(({ response }) => {
    return response.data;
  });

export const fetchAllActiveDivision = (divisionId) => API.get(`/service/allActiveDivision/${divisionId}`)
  .catch(({ response }) => {
    return response.data;
  });

export const assignTicket = (formData) => API.post(`/service/assignTicket/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const acknowledgeTicket = (formData) => API.post(`/service/acknowledgeTicket/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const dismissTicket = (formData) => API.post(`/service/dismissTicket/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const withdrawTicket = (formData) => API.post(`/service/withdrawTicket/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const closeTicket = (formData) => API.post(`/service/closeTicket/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const respondApproval = (formData) => API.post(`/service/respondApproval/${formData?.serviceRefno}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchTicketStatus = (type) => API.get(`/service/ticketStatus/${type}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchForwardReason = (divisionId) => API.get(`/service/forwardReason/${divisionId}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchApproval = (employeeNumber) => API.get(`/service/ticketApproval/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchUserInfo = (employeeNumber) => API.get(`/user/userInfo/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchDashboardData = (employeeNumber) => API.get(`/report/dashboard/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchServiceMonitorPermission = (employeeNumber) => API.get(`/report/serviceMonitorPermission/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchServiceMonitorData = (formData) => API.post('/report/serviceMonitorData', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchListOfServiceWithOneStatus = (formData) => API.post(`/service/listOfService/${formData?.employeeNumber || ''}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchAttachmentList = (refno, autoNo) => API.get(`/service/checkAttachment/${refno}/${autoNo}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchExternalUser = () => API.get('/service/externalUser')
  .catch(({ response }) => {
    return response.data;
  });

export const fetchExternalUserType = () => API.get('/service/externalUserType')
  .catch(({ response }) => {
    return response.data;
  });

export const createTask = (formData) => API.post('/service/createTask', formData)
  .catch(({ response }) => {
    return response.data;
  });
// Administrator (Zu)

// all external company
export const fetchExtCompChoosen = () => API.get('/admin/extcompanychoosen')
  .catch(({ response }) => {
    return response?.data;
  });

// all external company
export const fetchExtComp = () => API.get('/admin/externalcompanyview')
  .catch(({ response }) => {
    return response?.data;
  });

// all external company
export const fetchRegisteredVendCust = () => API.get('/admin/registeredvendcust')
  .catch(({ response }) => {
    return response?.data;
  });

// forgot password
export const forgotpassword = (formData) => API.post('/user/selfresetpass', formData)
  .catch((err) => {
    return err?.response || err?.message;
  });

// all vendor
export const fetchVendor = () => API.get('/admin/vendor')
  .catch(({ response }) => {
    return response?.data;
  });

// all vendor/customer account
export const fetchVendorAccount = () => API.get('/user/vendorAccount')
  .catch(({ response }) => {
    return response?.data;
  });

// all vendor
export const fetchVendorView = (id) => API.get(`/admin/vendorview/${id}`)
  .catch(({ response }) => {
    return response?.data;
  });

// all vendor in one division
export const fetchVendorInOneDivision = (id) => API.get(`/service/vendorInOneDivision/${id}`)
  .catch(({ response }) => {
    return response?.data;
  });

export const fetchUserDivisionID = (employeeNumber) => API.get(`/user/userDivisionID/${employeeNumber}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchServiceSolvedVia = (divisionId) => API.get(`/service/solvedVia/${divisionId}`)
  .catch(({ response }) => {
    return response.data;
  });

// all category
export const fetchCategory = () => API.get('/admin/category')
  .catch(({ response }) => {
    return response?.data;
  });

export const fetchCategoryID = (id) => API.get(`/admin/category/${id}`);

// all module
export const fetchModule = () => API.get('/admin/modules/');

export const fetchModuleID = (id) => API.get(`/admin/modules/${id}`);

// all department
export const fetchDepartment = () => API.get('/admin/department')
  .catch(({ response }) => {
    return response?.data;
  });

// all division
export const fetchDivision = () => API.get('/admin/division');

// all division view from ramco
export const fetchDivisionView = () => API.get('/admin/divisionview');

// all active division
export const fetchDivisionActive = () => API.get('/admin/divisionactive');

// fetchDivisionByName
export const fetchDivisionName = (id) => API.get(`/admin/division/${id}`);

// all division admin
export const fetchDA = () => API.get('/admin/divisionadmin')
  .catch(({ response }) => {
    return response?.data;
  });

// all division user
export const fetchDU = () => API.get('/admin/divisionuser')
  .catch(({ response }) => {
    return response?.data;
  });

// all user
export const fetchUser = () => API.get('admin/employees');

// all user include separated
export const fetchUserIncSeparated = () => API.get('admin/employeesincludeseparated');

// fetch user by employee number
export const fetchUserID = (id) => API.get(`/admin/user/${id}`);

// all severity
export const fetchSeverity = () => API.get('/admin/severity')
  .catch(({ response }) => {
    return response?.data;
  });

// all indicator
export const fetchIndicator = () => API.get('/admin/indicator')
  .catch(({ response }) => {
    return response?.data;
  });

// all status
export const fetchStatus = () => API.get('/admin/status')
  .catch(({ response }) => {
    return response?.data;
  });

// all forawarded reason
export const fetchReason = () => API.get('/admin/reason')
  .catch(({ response }) => {
    return response?.data;
  });

// fetch reason by id
export const fetchReasonID = (id) => API.get(`/admin/reason/${id}`);

// fetch faq by id
export const fetchFAQbyID = (id) => API.get(`/admin/faq/${id}`);

// all solution proposed
export const fetchSolvedVia = () => API.get('/admin/solvedvia');

export const fetchFAQ = () => API.get('/admin/faqs')
  .catch(({ response }) => {
    return response?.data;
  });

export const fetchAnswer = () => API.get('/admin/faqanswer')
  .catch(({ response }) => {
    return response?.data;
  });

export const fetchLocation = () => API.get('/admin/location')
  .catch(({ response }) => {
    return response?.data;
  });

export const addNewDivision = (formData) => API.post('/admin/newdivision', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const addNewVendor = (formData) => API.post('/admin/newvendor', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const addNewExtCompany = (formData) => API.post('/admin/newexternalcompany', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createCategory = (formData) => API.post('/admin/newcategory', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createModule = (formData) => API.post('/admin/newmodule', formData)
  .catch(({ response }) => {
    return response?.data;
  });

export const createIndicator = (formData) => API.post('/admin/newindicator', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createStatus = (formData) => API.post('/admin/newstatus', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createSeverity = (formData) => API.post('/admin/newseverity', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createReason = (formData) => API.post('/admin/newreason', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createSolved = (formData) => API.post('/admin/newsolvedvia', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createFAQ = (formData) => API.post('/admin/newfaq', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const createLocation = (formData) => API.post('/admin/newlocation', formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateUserPass = (formdata) => API.post('/user/updateuserpassword', formdata)
  .catch(({ response }) => {
    return response.data;
  });

export const resetPass = (formdata) => API.put('/user/resetpassword', formdata)
  .catch(({ response }) => {
    return response.data;
  });

// super admin reset user account password
export const resetPassbySA = (formdata) => API.put('/user/resetpasswordbySA', formdata)
  .catch(({ response }) => {
    return response.data;
  });

export const updateCategory = (id, formData) => API.put(`/admin/category/${id}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateModule = (id, formData) => API.put(`/admin/modules/${id}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateSolved = (id, formData) => API.put(`/admin/solvedvia/${id}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateSeverity = (id, formData) => API.put(`/admin/severity/${id}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateLocation = (id, formData) => API.put(`/admin/location/${id}`, formData)
  .catch(({ response }) => {
    return response.data;
  });

export const updateFwdReason = (id, formData) => API.put(`/admin/reason/${id}`, formData);

export const updateIndicator = (id, formData) => API.put(`/admin/indicator/${id}`, formData);

export const updateStatus = (id, formData) => API.put(`/admin/status/${id}`, formData);

export const updateAcc = (id, formData) => API.put(`/admin/divisionadmin/${id}`, formData);

export const updateAccActivate = (id, formData) => API.put(`/admin/activateuser&type/${id}`, formData);

export const updateDivisionStatus = (id, formData) => API.put(`/admin/divisionstatus/${id}`, formData);

export const updateVendorStatus = (id, formData) => API.put(`/admin/vendorstatus/${id}`, formData);

export const updateExternalCompStatus = (id, formData) => API.put(`/admin/externalcompanystatus/${id}`, formData);

export const updateFAQ = (id, formData) => API.put(`/admin/faq/${id}`, formData);

export const deleteUserAccount = (id) => API.delete(`/admin/useraccount/${id}`);

export const deleteStatus = (id, divisionid) => API.delete(`/admin/status/${id}/${divisionid}`);

export const deleteReason = (id) => API.delete(`/admin/reason/${id}`);

export const deleteSolved = (id) => API.delete(`/admin/solvedvia/${id}`);

export const deleteIndicator = (id) => API.delete(`/admin/indicator/${id}`);

export const deleteModule = (id) => API.delete(`/admin/module/${id}`);

export const deleteFAQ = (id) => API.delete(`/admin/faq/${id}`);

export const deleteVendor = (id) => API.delete(`/admin/vendor/${id}`);

export const deleteExternalComp = (id) => API.delete(`/admin/externalcompany/${id}`);

export const deleteLocation = (id) => API.delete(`/admin/location/${id}`);

export const fetchFaceIdData = (id) => API.get(`/user/retrieveFaceIdData/${id}`)
  .catch(({ response }) => {
    return response.data;
  });

export const fetchFaceIdToken = (id) => API.get(`/user/generateFaceIdToken/${id}`)
  .catch(({ response }) => {
    return response.data;
  });

export const createFaceIdData = (data) => API.post('/user/createFaceId', data)
  .catch(({ response }) => {
    return response.data;
  });

export const tokenLogin = (data) => API.post('/user/tokenLogin', data)
  .catch(({ response }) => {
    return response.data;
  });

export const listOfEquipmentType = (division) => API.get(`/admin/equipmentRequest/equipmentType/${division}`);

export const listOfAllEquipmentType = () => API.get('/admin/equipmentRequest/allEquipmentType');

export const addEquipmentType = (data) => API.post('/admin/equipmentRequest/addEquipmentType', data)
  .catch(({ response }) => {
    return response.data;
  });

export const updateEquipmentType = (data) => API.post('/admin/equipmentRequest/updateEquipmentType', data)
  .catch(({ response }) => {
    return response.data;
  });

export const deleteEquipmentType = (typeId, data) => API.post(`/admin/equipmentRequest/deleteEquipmentType/${typeId}`, data)
  .catch(({ response }) => {
    return response.data;
  });

export const listOfEquipmentItem = (division) => API.get(`/admin/equipmentRequest/equipmentItem/${division}`);

export const listOfAllEquipmentItem = () => API.get('/admin/equipmentRequest/allEquipmentItem');

export const addEquipmentItem = (data) => API.post('/admin/equipmentRequest/addEquipmentItem', data)
  .catch(({ response }) => {
    return response.data;
  });

export const updateEquipmentItem = (data) => API.post('/admin/equipmentRequest/updateEquipmentItem', data)
  .catch(({ response }) => {
    return response.data;
  });

export const deleteEquipmentItem = (typeId, data) => API.post(`/admin/equipmentRequest/deleteEquipmentItem/${typeId}`, data)
  .catch(({ response }) => {
    return response.data;
  });

export const listOfEquipmentPackage = (division) => API.get(`/admin/equipmentRequest/equipmentPackage/${division}`);

export const listOfAllEquipmentPackage = () => API.get('/admin/equipmentRequest/allEquipmentPackage');

export const addEquipmentPackage = (data) => API.post('/admin/equipmentRequest/addEquipmentPackage', data)
  .catch(({ response }) => {
    return response.data;
  });

export const updateEquipmentPackage = (data) => API.post('/admin/equipmentRequest/updateEquipmentPackage', data)
  .catch(({ response }) => {
    return response.data;
  });

export const deleteEquipmentPackage = (packageId, data) => API.post(`/admin/equipmentRequest/deleteEquipmentPackage/${packageId}`, data)
  .catch(({ response }) => {
    return response.data;
  });

export const listOfConsentAgreement = (division) => API.get(`/admin/equipmentRequest/ConsentAgreement/${division}`);

export const listOfAllConsentAgreement = () => API.get('/admin/equipmentRequest/allConsentAgreement');

export const addConsentAgreement = (data) => API.post('/admin/equipmentRequest/addConsentAgreement', data)
  .catch(({ response }) => {
    return response.data;
  });

export const updateConsentAgreement = (data) => API.post('/admin/equipmentRequest/updateConsentAgreement', data)
  .catch(({ response }) => {
    return response.data;
  });

export const deleteConsentAgreement = (agreementId, data) => API.post(`/admin/equipmentRequest/deleteConsentAgreement/${agreementId}`, data)
  .catch(({ response }) => {
    return response.data;
  });

export const listOfActiveEquipmentType = () => API.get('/equipment/equipmentRequest/activeEquipmentType');

export const listOfActiveEquipmentItem = () => API.get('/equipment/equipmentRequest/activeEquipmentItem');

export const listOfActiveEquipmentPackage = () => API.get('/equipment/equipmentRequest/activeEquipmentPackage');

export const listOfActiveConsentAgreement = () => API.get('/equipment/equipmentRequest/activeConsentAgreement');

export const createEquipmentRequest = (data) => API.post('/equipment/createEquipmentRequest', data);

export const fetchEquipmentRequest = (empno) => API.get(`/equipment/equipmentRequest/${empno}`);

export const respondEquipmentRequestApproval = (data) => API.post('/equipment/respondEquipmentRequestApproval', data);

export const fetchOneConsentAgreement = (id) => API.get(`/equipment/fetchOneConsentAgreement/${id}`);

export const fetchOneMFARecord = (id) => API.get(`/admin/MFA/oneMFARecord/${id}`);

export const createMFAMethod = (data) => API.post('/admin/MFA/addMFAMethod', data);

export const updateMFAMethod = (data) => API.put('/admin/MFA/updateMFAMethod', data);

export const createMFARecord = (data) => API.post('/admin/MFA/addMFARecord', data);

export const updateMFARecord = (data) => API.put('/admin/MFA/updateMFARecord', data);

export const fetchActiveMFAMethod = () => API.get('/admin/MFA/allActiveMFAMethod');

export const fetchAllMFAMethod = () => API.get('/admin/MFA/allMFAMethod');

export const disableMFARecord = (data) => API.put('/admin/MFA/disableMFARecord', data);

export const fetchAllAccountMFARecord = () => API.get('/admin/MFA/allAccountMFARecord');

export const totpValidate = (data) => API.post('/user/totp/validate', data);

export const totpVerify = (data) => API.post('/user/totp/verify', data);

export const totpGenerate = (data) => API.post('/user/totp/generate', data);

export const checkMethod = (data) => API.get(`/user/auth/checkMethod/id/${data}`);

export const checkAccountMFA = (data) => API.get(`/user/auth/checkAccountMFA/id/${data}`);

export const bulkManageMFARecord = (data) => API.put('/admin/MFA/bulkManageMFARecord', data);
