import * as api from '../api/index';

export const addNewDivision = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addNewDivision(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createCategory = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createCategory(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const addNewVendor = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addNewVendor(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const addNewExtComp = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.addNewExtCompany(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const addNewLocation = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createLocation(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createModule = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createModule(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createIndicator = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createIndicator(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createStatus = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.createStatus(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const createSeverity = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createSeverity(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createSolved = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createSolved(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createReason = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createReason(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const createFAQ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.createFAQ(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

// export const registerUser = (formData, history) => async (dispatch) => {
//   try {
//     const { data } = await api.register(formData);

//     dispatch({ type: 'AUTH', data });

//     history('/app/administrator');
//   } catch (error) {
//     console.log(error);
//   }
// };

export const registerbysuperadmin = (
  formData,
) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.registerbysuperadmin(formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((error) => {
        return error;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

// export const login = (formData, history) => async (dispatch) => {
//   try {
//     dispatch({ type: 'SHOW_LOADING_SPINNER' });
//     const response = await api.login(formData);
//     const { data } = response;

//     if (response?.success === false) {
//       dispatch({ type: 'HIDE_LOADING_SPINNER' });
//       return response.message;
//     }
//     dispatch({ type: 'AUTH', payload: data });
//     dispatch({ type: 'HIDE_LOADING_SPINNER' });
//     history('/app/dashboard');
//     return 'Success';
//   } catch (error) {
//     return error;
//   }
// };

export const updateSolved = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateSolved(id, formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateSeverity = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateSeverity(id, formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateCategory = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateCategory(id, formData)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateModule = (id, formdata) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const data = await api.updateModule(id, formdata)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    console.log('error : ', error);
    return error;
  }
};

export const updateDA = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateAcc(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateAccTyeActivate = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateAccActivate(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateDivisionStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateDivisionStatus(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateReason = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateFwdReason(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateIndcator = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateIndicator(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateStatus(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateFAQ = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateFAQ(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateVendorStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateVendorStatus(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateExtCompStatus = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateExternalCompStatus(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const updateLocation = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.updateLocation(id, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return error;
  }
};

export const listCategory = () => async (/* dispatch */) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchCategory()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    // dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const listDepartment = () => async () => {
  try {
    const { data } = await api.fetchDepartment()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listModule = () => async (/* dispatch */) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchModule()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    // dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

// export const listDA = () => async () => {
//   try {
//     const { data } = await api.fetchDA()
//       .then((res) => {
//         return res;
//       })
//       .catch((err) => {
//         return err;
//       });
//     return data;
//   } catch (error) {
//     return (error);
//   }
// };

export const listDA = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchDA()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listDU = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchDU()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listUsers = () => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchUser()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listUsersIncSeparated = () => async (dispatch) => {
  try {
    // dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchUserIncSeparated()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listDiv = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.fetchDivision()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listDivView = () => async () => {
  try {
    const { data } = await api.fetchDivisionView()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listDivActive = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const { data } = await api.fetchDivisionActive()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return data;
  } catch (error) {
    return (error);
  }
};

export const listSeverity = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchSeverity()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listIndicator = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchIndicator()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listStatus = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchStatus()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listSolve = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchSolvedVia()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listReason = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchReason()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listFAQ = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchFAQ()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listAnswer = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchAnswer()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listVendor = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchVendor()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listVendorView = (id) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchVendorView(id)
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listExternalComp = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchExtCompChoosen()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listExternalCompView = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchExtComp()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listRegisteredVendorCustomer = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchRegisteredVendCust()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const listLocation = () => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.fetchLocation()
      .then((res) => {
        return res?.data || res;
      })
      .catch((err) => {
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return (error);
  }
};

export const categoryById = (id) => async () => {
  try {
    const detail = await api.fetchCategoryID(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const moduleById = (id) => async () => {
  try {
    const detail = await api.fetchModuleID(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const userById = (id) => async () => {
  try {
    const detail = await api.fetchUserID(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const DivByName = (id) => async () => {
  try {
    const detail = await api.fetchDivisionName(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const ReasonById = (id) => async () => {
  try {
    const detail = await api.fetchReasonID(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const faqById = (id) => async () => {
  try {
    const detail = await api.fetchFAQbyID(id)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        return err;
      });
    return detail;
  } catch (error) {
    return (error);
  }
};

export const deleteStatus = (id, divisionid) => async () => {
  try {
    const { data } = await api.deleteStatus(id, divisionid)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteReason = (id) => async () => {
  try {
    const { data } = await api.deleteReason(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteSolved = (id) => async () => {
  try {
    const { data } = await api.deleteSolved(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteIndicator = (id) => async () => {
  try {
    const { data } = await api.deleteIndicator(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteModule = (id) => async () => {
  try {
    const { data } = await api.deleteModule(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteUserAcc = (id) => async () => {
  try {
    const { data } = await api.deleteUserAccount(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteFAQ = (id) => async () => {
  try {
    const { data } = await api.deleteFAQ(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteVendor = (id) => async () => {
  try {
    const { data } = await api.deleteVendor(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteExternalCompany = (id) => async () => {
  try {
    const { data } = await api.deleteExternalComp(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteLocation = (id) => async () => {
  try {
    const { data } = await api.deleteLocation(id)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
    return data;
  } catch (error) {
    return error;
  }
};

export const getOneMFARecord = (id) => async () => {
  try {
    const response = await api.fetchOneMFARecord(id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });

    return response;
  } catch (error) {
    return error;
  }
};

export const createMFARecord = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.createMFARecord(data)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMFARecord = (data) => async () => {
  try {
    const response = await api.updateMFARecord(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchActiveMFAMethod = () => async () => {
  try {
    const response = await api.fetchActiveMFAMethod()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const createMFAMethod = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.createMFAMethod(data)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMFAMethod = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.updateMFAMethod(data)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllMFAMethod = () => async () => {
  try {
    const response = await api.fetchAllMFAMethod()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllAccountMFARecord = () => async () => {
  try {
    const response = await api.fetchAllAccountMFARecord()
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const disableMFARecord = (data) => async () => {
  try {
    const response = await api.disableMFARecord(data)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export const bulkManageMFARecord = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'SHOW_LOADING_SPINNER' });
    const response = await api.bulkManageMFARecord(data)
      .then((res) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return res?.data;
      })
      .catch((err) => {
        dispatch({ type: 'HIDE_LOADING_SPINNER' });
        return err;
      });
    dispatch({ type: 'HIDE_LOADING_SPINNER' });
    return response;
  } catch (error) {
    return error;
  }
};

export default updateDA;
