/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
  TextareaAutosize
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import {
  updateTicket,
  fetchSeverity,
  fetchModule,
  fetchStatus,
  fetchSolvedVia,
  fetchVendor,
  fetchTicketDetails
} from '../../../actions/service';
import { maxSelectFile, checkFileSize } from '../../../components/fileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark
  },
  textArea: {
    width: '100%',
    padding: '1%'
  }
}));

function UpdateTicketForm({
  serviceRefno,
  user,
  // setOpenPopup,
  openUpdate,
  setOpenUpdate,
  accountType,
  currentPage,
  refresh,
  setRefresh,
  currentModule,
  currentSeverity,
  type
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [listOfSeverity, setListOfSeverity] = useState([]);
  const [listOfModule, setListOfModule] = useState([]);
  const [listOfStatus, setListOfStatus] = useState([]);
  const [listOfVendor, setListOfVendor] = useState([]);
  const [openVendorSelection, setOpenVendorSelection] = useState(false);
  const [openSolvedVia, setOpenSolvedVia] = useState(false);
  const [openUnresolvedNotice, setOpenUnresolvedNotice] = useState(false);
  const [openResubmitRequest, setOpenResubmitRequest] = useState(false);
  const [tempAttachment, setAttachment] = useState([]);
  const [solvedVia, setSolvedVia] = useState([]);
  const [severityImpact, setSeverityImpact] = useState('');
  const [severityId, setSeverityId] = useState(currentSeverity);
  const [tempDescription, setTempDescription] = useState('');
  const [tempServiceStatus, setTempServiceStatus] = useState('');
  const [tempVendor, setTempVendor] = useState('');
  const [tempModule, setTempModule] = useState(currentModule || '');
  const [tempExternalRefname, setTempExternalRefname] = useState('');
  const [tempExternalRefno, setTempExternalRefno] = useState('');
  const [headers, setHeaders] = useState([]);

  const fetchData = () => {
    dispatch(fetchSeverity(user?.EMPLOYEE_NUMBER))
      .then((res) => {
        setListOfSeverity(res?.severity || []);
      });
    dispatch(fetchModule(user?.EMPLOYEE_NUMBER))
      .then((res) => {
        if (res?.success === true) {
          if (type === 'request') {
            setListOfModule(res?.requestModules || []);
          } else {
            setListOfModule(res?.ticketModules || []);
          }
        }
      });
    dispatch(fetchStatus(type))
      .then((res) => {
        setListOfStatus(res?.status || []);
      });
    dispatch(fetchSolvedVia(user?.DIVISION_ID || ''))
      .then((res) => {
        setSolvedVia(res?.solvedVia || []);
      });
    dispatch(fetchVendor(user?.DIVISION_ID || ''))
      .then((res) => {
        setListOfVendor(res?.vendor || []);
      });
    // Retrieve Ticket details
    dispatch(fetchTicketDetails(serviceRefno))
      .then((res) => {
        setHeaders(res?.data.header || []);
      });
  };

  const reset = () => {
    setOpenResubmitRequest(false);
    setOpenUnresolvedNotice(false);
    setOpenVendorSelection(false);
    setOpenSolvedVia(false);
  };

  const handleSeverity = (severity) => {
    setSeverityId(severity?.SEVERITY_ID);
    setSeverityImpact(severity?.IMPACT);
  };

  const handleClickStatus = (status) => {
    setTempServiceStatus(status);
    reset();
    if (status === 'RVA') {
      setOpenVendorSelection(true);
    } else if (status === 'SOL') {
      setTempVendor('');
      setOpenSolvedVia(true);
    } else if (status === 'URS') {
      setTempVendor('');
      setOpenUnresolvedNotice(true);
    } else if (status === 'RSR') {
      setTempVendor('');
      setOpenResubmitRequest(true);
    } else {
      setTempVendor('');
      setOpenResubmitRequest(false);
      setOpenUnresolvedNotice(false);
      setOpenVendorSelection(false);
      setOpenSolvedVia(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  // Show this section if user account type is division user, division admin, superadmin
  if (accountType !== 'User' && currentPage !== 'NormalView') {
    return (
      <Box
        bgcolor="common.white"
        color="text.primary"
        m={2}
        borderRadius={12}
      >
        <Formik
          enableReinitialize
          initialValues={{
            attachment: tempAttachment,
            description: tempDescription,
            severity: severityId,
            severity_impact: severityImpact,
            module: tempModule,
            vendor: tempVendor,
            service_refno: serviceRefno,
            service_status: tempServiceStatus,
            email: user?.EMAIL_ADDRESS,
            name: user?.EMPLOYEE_NAME,
            employee_number: user?.EMPLOYEE_NUMBER,
            superior_employee_number: user?.SUPERVISOR_NUMBER,
            superior_name: user?.SUPERVISOR_NAME,
            external_refno: tempExternalRefno,
            external_refname: tempExternalRefname,
            delegate_flag: headers.some((header) => header.DELEGATE_FLAG)
          }}
          validationSchema={
            Yup.object().shape({
              description: Yup.string().max(500).min(5).required('Please provide description.'),
              service_status: Yup.string().max(3).min(3).required('Please select status.'),
              external_refno: Yup.string().max(30),
              external_refname: Yup.string().max(250)
            })
          }
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            const data = new FormData();
            // data.append('attachment', tempAttachment);
            for (const value in values) {
              data.append(value, values[value]);
            }

            for (let x = 0; x < tempAttachment?.length; x++) {
              console.log(tempAttachment[x]);
              data.append('attachment', tempAttachment[x]);
            }
            setSubmitting(false);
            dispatch(updateTicket(data))
              .then((res) => {
                if (!res?.data) {
                  if (res?.success !== true) {
                    setSubmitting(false);
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier,
                      },
                    });
                  } else {
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier,
                      },
                    });
                    setRefresh(!refresh);
                    setOpenUpdate(!openUpdate);
                  }
                } else {
                  if (res?.data.success !== true) {
                    setSubmitting(false);
                    enqueueSnackbar({
                      message: res?.data.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.data.notifier,
                      },
                    });
                  } else {
                    // setOpenPopup(false);
                    enqueueSnackbar({
                      message: res?.data.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.data.notifier,
                      },
                    });
                    setRefresh(!refresh);
                    setOpenUpdate(!openUpdate);
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <Box
              p={2}
            >
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                {headers.some((header) => header.DELEGATE_FLAG !== 'true') && (
                  <TextField
                    error={Boolean(touched.severity && errors.severity)}
                    fullWidth
                    helperText={touched.severity && errors.severity}
                    label="Severity"
                    id="severity"
                    margin="normal"
                    name="severity"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.severity}
                    variant="outlined"
                    select
                  >
                    <MenuItem value="">None</MenuItem>
                    {listOfSeverity.map((severity) => (
                      <MenuItem
                        value={severity?.SEVERITY_ID}
                        onClick={() => handleSeverity(severity)}
                      >
                        {severity?.SEVERITY_ID === currentSeverity && ('(Current) ')}
                        {severity?.SEVERITY_ID === currentSeverity
                          && (setSeverityImpact(severity?.IMPACT))}
                        {severity?.IMPACT}
                      </MenuItem>
                    ))}
                  </TextField>
                )}

                {headers.some((header) => header.DELEGATE_FLAG !== 'true') && (
                  <Autocomplete
                    id="module"
                    options={listOfModule}
                    getOptionLabel={(option) => option?.MODULE_DESCRIPTION || ''}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: 'module',
                          value: newValue ? newValue.MODULE_ID : ''
                        }
                      });
                      if (newValue) {
                        setTempModule(newValue.MODULE_ID);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Module"
                        variant="outlined"
                        margin="normal"
                        error={Boolean(touched.module && errors.module)}
                        helperText={touched.module && errors.module}
                        onBlur={handleBlur}
                        fullWidth
                      />
                    )}
                    value={listOfModule.find(
                      (module) => module?.MODULE_ID === values.module
                    ) || null}
                    disableClearable
                  />
                )}

                <br />
                <TextareaAutosize
                  rowsMax={10}
                  rowsMin={10}
                  name="description"
                  aria-label="maximum height"
                  placeholder="Update Description"
                  className={classes.textArea}
                  error={Boolean(touched.description && errors.description)}
                  fullwidth="true"
                  helperText={touched.description && errors.description}
                  label="Description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeCapture={(e) => {
                    setTempDescription(e.target.value);
                  }}
                  value={values.description}
                />
                {(errors.description && touched.description) && (
                  <Alert severity="error">
                    {errors.description || ''}
                  </Alert>
                )}
                <br />

                <Button
                  error={Boolean(touched.attachment && errors.attachment)}
                  fullWidth
                  helperText={touched.attachment && errors.attachment}
                  label="Attachment"
                  variant="outlined"
                  focused
                  component="label"
                  onBlur={handleBlur}
                  margin="normal"
                >
                  Attachment :
                  {' '}
                  <input
                    id="attachment"
                    name="attachment"
                    onChange={(event) => {
                      console.log(event.currentTarget.files);
                      console.log(event.target.files);
                      setAttachment([]);
                      setFieldValue('attachment', []);
                      if (maxSelectFile(event) && checkFileSize(event)) {
                        setAttachment(event.target.files || []);
                        setFieldValue('attachment', event.currentTarget.files[0] || []);
                      }
                    }}
                    onClick={(e) => {
                      e.target.value = '';
                    }}
                    type="file"
                    multiple
                  />
                </Button>

                <br />
                <br />

                <TextField
                  error={Boolean(touched.external_refname && errors.external_refname)}
                  fullWidth
                  helperText={touched.external_refname && errors.external_refname}
                  label="External Reference Name (Leave blank if unrelated)"
                  id="external_refname"
                  margin="normal"
                  name="external_refname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeCapture={(e) => {
                    setTempExternalRefname(e.target.value);
                  }}
                  type="text"
                  value={values.external_refname}
                  variant="outlined"
                />
                <br />
                <TextField
                  error={Boolean(touched.external_refno && errors.external_refno)}
                  fullWidth
                  helperText={touched.external_refno && errors.external_refno}
                  label="External Reference Number (Leave blank if unrelated)"
                  id="external_refno"
                  margin="normal"
                  name="external_refno"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeCapture={(e) => {
                    setTempExternalRefno(e.target.value);
                  }}
                  type="text"
                  value={values.external_refno}
                  variant="outlined"
                />
                <br />
                {/* <TextField
                  error={Boolean(touched.service_status && errors.service_status)}
                  fullWidth
                  helperText={touched.service_status && errors.service_status}
                  label="Ticket Status"
                  id="service_status"
                  margin="normal"
                  name="service_status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.service_status}
                  variant="outlined"
                  select
                >
                  <MenuItem value="" default>None</MenuItem>
                  {listOfStatus.map((status) => (
                    <MenuItem
                      value={status?.STATUS_ID}
                      onClick={() => handleClickStatus(status?.STATUS_ID)}
                    >
                      {status?.STATUS_DESCRIPTION}
                    </MenuItem>
                  ))}
                </TextField> */}

                <TextField
                  error={Boolean(touched.service_status && errors.service_status)}
                  fullWidth
                  helperText={touched.service_status && errors.service_status}
                  label="Ticket Status"
                  id="service_status"
                  margin="normal"
                  name="service_status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.service_status}
                  variant="outlined"
                  select
                >
                  <MenuItem value="" default>None</MenuItem>
                  {listOfStatus
                    .filter((status) => {
                      if (/* accountType === 'Vendor User' && */ headers.some((header) => header.DELEGATE_FLAG === 'true'/* && header.CURRENT_PIC === user?.EMPLOYEE_NUMBER */)) {
                        return status?.STATUS_ID !== 'RSR' && status?.STATUS_ID !== 'URS' && status?.STATUS_ID !== 'SOL' && status?.STATUS_ID !== 'PUR' && status?.STATUS_ID !== 'RVA' && status?.STATUS_ID !== 'POD';
                      }
                      return status?.STATUS_ID !== 'TSK'; // Include all statuses for other condition
                    })
                    .map((status) => (
                      <MenuItem
                        value={status?.STATUS_ID}
                        onClick={() => handleClickStatus(status?.STATUS_ID)}
                        key={status?.STATUS_ID}
                      >
                        {status?.STATUS_DESCRIPTION}
                      </MenuItem>
                    ))}
                </TextField>

                {openVendorSelection === true && (
                  <>
                    <TextField
                      error={Boolean(touched.vendor && errors.vendor)}
                      fullWidth
                      helperText={touched.vendor && errors.vendor}
                      label="Vendor"
                      id="vendor"
                      margin="normal"
                      name="vendor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.vendor}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="" default>None</MenuItem>
                      {listOfVendor.map((row) => (
                        <MenuItem
                          value={row?.VENDOR_ID}
                          onClick={() => {
                            setTempVendor(row?.VENDOR_ID);
                          }}
                        >
                          {row?.VENDOR_NAME}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}

                {openSolvedVia === true && (
                  <>
                    <TextField
                      error={Boolean(touched.solved_via && errors.solved_via)}
                      fullWidth
                      helperText={touched.solved_via && errors.solved_via}
                      label="Solved Via"
                      id="solved_via"
                      margin="normal"
                      name="solved_via"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.solved_via}
                      variant="outlined"
                      select
                    >
                      <MenuItem value="none" default>None</MenuItem>
                      {solvedVia.map((row) => (
                        <MenuItem
                          value={row?.SOLVED_ID}
                        >
                          {row?.SOLVED_DESCRIPTION}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
                {openUnresolvedNotice === true && (
                  <>
                    <br />
                    <Typography>
                      <Box fontWeight="fontWeightBold" m={1}>
                        Attention!
                      </Box>
                      <Box fontStyle="italic" m={1}>
                        {`This ticket will request approval from (${user?.SUPERVISOR_NUMBER}) ${user?.SUPERVISOR_NAME}.`}
                      </Box>
                      <Box fontStyle="italic" m={1}>
                        Please inform Human Resource if above person is not your supervisor.
                        Thank you.
                      </Box>
                    </Typography>
                    <br />
                  </>
                )}
                {openResubmitRequest === true && (
                  <>
                    <br />
                    <Box p={1}>
                      <Typography>
                        <Box fontWeight="fontWeightBold" m={1}>
                          Attention!
                        </Box>
                        <Box fontStyle="italic" m={1}>
                          {`This request will be resubmit to (${values.superior_employee_number}) ${values.superior_name} for approval.`}
                        </Box>
                        <Box fontStyle="italic" m={1}>
                          Re-check your input to avoid any mistakes.
                        </Box>
                        <Box fontStyle="italic" m={1}>
                          Please inform Human Resource if above person is not your supervisor.
                          Thank you.
                        </Box>
                      </Typography>
                    </Box>
                    <br />
                  </>
                )}
                <Button
                  id="submit"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleChange}
                >
                  Update
                </Button>
              </form>

            </Box>
          )}
        </Formik>
      </Box>
    );
  }

  // Show this section if user is at normal view page.
  if (currentPage === 'NormalView') {
    return (
      <Box
        bgcolor="common.white"
        color="text.primary"
        m={2}
        borderRadius={12}
      >
        <Formik
          enableReinitialize
          initialValues={{
            description: tempDescription,
            attachment: tempAttachment,
            service_refno: serviceRefno,
            service_status: 'UPD',
            email: user?.EMAIL_ADDRESS,
            name: user?.EMPLOYEE_NAME,
            employee_number: user?.EMPLOYEE_NUMBER,

          }}
          validationSchema={
            Yup.object().shape({
              description: Yup.string().max(500).min(5).required('Please provide description.'),
            })
          }
          onSubmit={(values, { setSubmitting }) => {
            const data = new FormData();
            // data.append('attachment', tempAttachment);
            for (const value in values) {
              data.append(value, values[value]);
            }

            for (let x = 0; x < tempAttachment?.length; x++) {
              data.append('attachment', tempAttachment[x]);
            }

            dispatch(updateTicket(data))
              .then((res) => {
                if (!res?.data) {
                  if (res?.success !== true) {
                    setSubmitting(false);
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier,
                      },
                    });
                  } else {
                    setRefresh(!refresh);
                    enqueueSnackbar({
                      message: res?.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.notifier || 'success',
                      },
                    });
                  }
                } else {
                  if (res?.data.success !== true) {
                    setSubmitting(false);
                    enqueueSnackbar({
                      message: res?.data.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.data.notifier,
                      },
                    });
                  } else {
                    // setOpenPopup(false);
                    setRefresh(!refresh);
                    enqueueSnackbar({
                      message: res?.data.message,
                      options: {
                        key: new Date().getTime() + Math.random(),
                        action: (key) => (
                          <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
                        ),
                        success: res?.data.notifier,
                      },
                    });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <Box
              p={2}
            >
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label="Update Description"
                  id="description"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeCapture={(e) => {
                    setTempDescription(e.target.value);
                  }}
                  type="text"
                  value={values.description}
                  variant="outlined"
                  multiline
                />
                <Button
                  error={Boolean(touched.attachment && errors.attachment)}
                  fullWidth
                  helperText={touched.attachment && errors.attachment}
                  label="Attachment"
                  variant="outlined"
                  focused
                  component="label"
                  onBlur={handleBlur}
                  margin="normal"
                >
                  <input
                    id="attachment"
                    name="attachment"
                    onChange={(event) => {
                      setAttachment([]);
                      setFieldValue('attachment', []);
                      if (maxSelectFile(event) && checkFileSize(event)) {
                        setAttachment(event.target.files || []);
                        setFieldValue('attachment', event.currentTarget.files[0] || []);
                      }
                    }}
                    onClick={(e) => {
                      e.target.value = '';
                    }}
                    type="file"
                    multiple
                  />
                </Button>

                <br />
                <br />

                <Button
                  id="submit"
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={handleChange}
                >
                  Update
                </Button>
              </form>

            </Box>
          )}
        </Formik>
      </Box>
    );
  }
  return (
    <>
      Action unavailable for this account.
    </>
  );
}

UpdateTicketForm.propTypes = {
  serviceRefno: PropTypes.string,
  user: PropTypes.object,
  // setOpenPopup: PropTypes.bool,
  accountType: PropTypes.string,
  currentPage: PropTypes.string,
  refresh: PropTypes.string,
  setRefresh: PropTypes.bool,
  currentSeverity: PropTypes.string,
  currentModule: PropTypes.string,
  type: PropTypes.string,
  openUpdate: PropTypes.string,
  setOpenUpdate: PropTypes.bool,
};

export default UpdateTicketForm;
